import axios from 'axios';
import { ElMessage } from 'element-plus'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, removeToken } from '@/utils/auth'
import type { AxiosError, AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import router from "@/router/index";

const baseURL = import.meta.env.VITE_API_BASE_URL
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service:AxiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000
});

async function doService(promise:Promise<any>) {
    try {
        const data = await promise;
        NProgress.done();
        return data;
    } catch (err) {
        NProgress.done();
        return err;
    }
  }

service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
       if(getToken()) {
         (config.headers || {})['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
       }
       if (config && (config as any).type === 'form') {
            (config.headers || {})['Content-Type'] = 'application/x-www-form-urlencoded'
        }
        // config.url = config.baseURL ? config.baseURL + config.url : ''
        return config;
    },
    (error: AxiosError) => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.status === 200) {
            if(response.data.code == 200){
                return response.data;
            } else if(response.data.code == 401) {
                removeToken()
                router.replace('/login'); // 跳转到登录页面
            }else{
                ElMessage.error(response.data.error)
                return Promise.reject();
            }
        } else {
            ElMessage.error('网络请求错误')
            return Promise.reject();
        }
    },
    (error: AxiosError) => {
        ElMessage.error(error.message)
        return Promise.reject();
    }
);

const request = async function (config:AxiosRequestConfig) {
    NProgress.start()
    return await doService(service(config))
  }
  // export default service
  export default request