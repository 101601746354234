import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw,_RouteRecordBase } from 'vue-router'
import Layout from '@/layout/index.vue'

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
 export const constantRoutes:RouteRecordRaw[] = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {title: '用户登录', hidden: true},
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401.vue'),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import ("@/views/home/index.vue"),
        name: 'Home',
        meta: { title: '数据报表', icon: 'HomeFilled', affix: true }
      }
    ]
  },
  {
    path: '/nextLevelUser',
    component: Layout,
    redirect: '/nextLevelUser',
    children: [
      {
        path: '',
        component: () => import ("@/views/nextLevelUsers/index.vue"),
        name: 'NextLevelUser',
        meta: { title: '下级用户', icon: 'UserFilled', affix: true }
      }
    ]
  },
  {
    path: '/rechargeOrder',
    component: Layout,
    redirect: '/rechargeOrder',
    children: [
      {
        path: '',
        component: () => import ("@/views/rechargeOrder/index.vue"),
        name: 'RechargeOrder',
        meta: { title: '充值订单', icon: 'CirclePlusFilled', affix: true }
      }
    ]
  },
  {
    path: '/brokerageWithDraw',
    component: Layout,
    redirect: '/brokerageWithDraw',
    children: [
      {
        path: '',
        component: () => import ("@/views/brokerageWithDraw/index.vue"),
        name: 'BrokerageWithDraw',
        meta: { title: '佣金提现', icon: 'Money', affix: true }
      }
    ]
  },
  {
    path: '/cdkMake',
    component: Layout,
    redirect: '/cdkMake',
    children: [
      {
        path: '',
        component: () => import ("@/views/cdkMake/index.vue"),
        name: 'CdkMake',
        meta: { title: 'CDK制作', icon: 'Platform', affix: true }
      }
    ]
  },
  {
    path: '/rollRoomManage',
    component: Layout,
    redirect: '/rollRoomManage',
    children: [
      {
        path: '',
        component: () => import ("@/views/rollRoomManage/index.vue"),
        name: 'rollRoomManage',
        meta: { title: 'ROLL房管理', icon: 'Management', affix: true }
      }
    ]
  },
  // {
  //   path: '/tree',
  //   component: Layout,
  //   redirect: '/tree/base',
  //   name: 'Tree',
  //   meta: {title: '充值订单', icon: 'Picture', alwaysShow: true },
  //   children: [
  //     {
  //       path: 'base',
  //       component: () => import('@/views/tree/baseTree.vue'),
  //       name: 'TreeBase',
  //       meta: { title: '充值订单'}
  //     },
  //   ]
  // },
  // {
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes:RouteRecordRaw[] = [

  // 404 page must be placed at the end !!!
  { path: '/:pathMatch(.*)', redirect: '/404', hidden: true }
]

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    ...constantRoutes,
    ...asyncRoutes
  ]
})

export const addRoutes = (routes:RouteRecordRaw[]) => {
  for(let i = 0; i < routes.length; i++) {
    router.addRoute(routes[i])
  }
}

export default router
