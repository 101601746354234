import request from '@/utils/request';

// 登录
export const login = (data: FormData) => {
    return request({
        url: '/api/login',
        method: 'post',
        data
    });
};

// 退出登录
export const logout = (data?:Record<string, string>) => {
    return request({
        url: '/api/login-out',
        method: 'post',
        data
    });
};

// 下级用户
export const nextLevelUser = (data:any) => {
    return request({
        url: `/api/next-user?page=${data.page}`,
        method: 'get',
        data
    });
};

// 数据报表
export const dataReport = (data?:Record<string, string>) => {
    return request({
        url: '/api/data-report',
        method: 'get',
        data
    });
};


// 充值订单
export const rechargeList = (data:any) => {
    return request({
        url: `/api/recharge-list?page=${data.page}`,
        method: 'get'
    });
};

// 主播流水
export const trunover = (data? :Record<string, string>) => {
    return request({
        url: '/api/turnover',
        method: 'get',
        data
    });
};

// 申请提现
export const withdraw = (data? : any ) => {
    return request({
        url: '/api/withdraw',
        method: 'post',
        data
    });
};
