import { defineStore } from 'pinia'
import { login, logout } from '@/api/user'
import { setToken, getToken, removeToken } from '@/utils/auth'
export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    token: getToken(),
    avatar: '',
    roles: []
  }),
  getters: {
  },
  actions: {
    async getUserInfo() {
      return true
    },
    async login(formData: FormData, userInfo: {username: string, password: string}) {
      const { username, password } = userInfo
      if (username && password) {
        const res = await login(formData)
        if (res.data.access_token) {
          this.token = res.data.access_token
          setToken(res.data.access_token)
          return true
        }
      }
      return false
    },
    async logout() {
      if (this.token === '') {
        throw Error('Logout: token is undefined!')
      }
      const res = await logout()
      if(res && res.data) {
        this.token = ''
        removeToken()
      }
      return true
    },
    resetToken() {
      // this.avatar = avatar
    }
  }
})
